import Link from "next/link";
import { FC, ReactNode } from "react";

import { classNames } from "@frec-js/common-web";
import { AnimatedIcon } from "../components/Button";

type HomeBannerProps = {
  className?: string;
  href?: string;
  ctaText?: string;
  icon?: ReactNode;
  children: ReactNode;
};

export const HomeBanner: FC<HomeBannerProps> = ({
  className,
  href,
  ctaText,
  icon,
  children,
}) => {
  return (
    <HomeBannerWrapper className={className} href={href}>
      <span className="text-14 text-center">{children}</span>
      {(icon || ctaText) && (
        <div className="flex items-center justify-center gap-2">
          {icon && icon}
          {ctaText && (
            <span className="flex items-center justify-center gap-1">
              <span className="text-14 w-min font-medium text-nowrap sm:w-auto">
                {ctaText}
              </span>
              <AnimatedIcon small />
            </span>
          )}
        </div>
      )}
    </HomeBannerWrapper>
  );
};

type HomeBannerWrapperProps = {
  className?: string;
  href?: string;
  children: ReactNode;
};

const HomeBannerWrapper: FC<HomeBannerWrapperProps> = ({
  className,
  href,
  children,
}) => {
  const props = {
    className: classNames(
      "bg-frecNeon group py-2 md:py-1 flex h-fit w-full items-center justify-center gap-2 border-b border-black px-2 flex-wrap",
      className,
    ),
  };

  if (href) {
    return (
      <Link {...props} target="_blank" rel="noreferrer" href={href}>
        {children}
      </Link>
    );
  }

  return <div {...props}>{children}</div>;
};
