import { CloseButton, Modal } from "@mantine/core";
import lottie from "lottie-web/build/player/lottie_light";
import Image from "next/image";
import { useRouter } from "next/router";
import { FC, useCallback, useEffect, useRef, useState } from "react";

import { classNames } from "@frec-js/common-web";
import { BubbleText } from "../../components/BubbleText";
import { Button, smallOutlineClassNames } from "../../components/Button";
import Scrollie from "../../public/webstatic/images/scrollie.gif";
import Tappie from "../../public/webstatic/images/tappie.png";
import animationDataMobile from "../../public/webstatic/lottie/di-animation-mobile.json";
import animationData from "../../public/webstatic/lottie/di-animation.json";
import { FadeInZoom } from "../../utils/animations";
import { FrecWebPath } from "../../utils/paths";
import { windowIfAvailable } from "../../utils/windowIfAvailable";

export const NextLevelInvesting: FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const lottieRef = useRef<HTMLDivElement>(null);
  const introRef = useRef<HTMLDivElement>(null);
  const outroRef = useRef<HTMLDivElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const topGridRef = useRef<HTMLDivElement>(null);
  const bottomGridRef = useRef<HTMLDivElement>(null);

  const [launched, setLaunched] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [renderCount, setRenderCount] = useState(0); // silly fn to rerender

  useEffect(() => {
    if (!launched) return;
    if (!lottieRef.current) return setRenderCount((c) => c + 1);
    const anim = lottie.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: isMobile ? animationDataMobile : animationData,
      rendererSettings: {
        title: "Direct Indexing - Next level investing",
        description:
          "Imagine you owned the individual stocks of SPY. When a stock loses value, we can harvest its loss by temporarily swapping it for a different one while tracking the index. These tax loses accumulate over time and can be used as a deduction against your tax bill.",
      },
    });

    const onScroll = () => {
      // Our animation plays inside a container. The animation will play as we scroll through the container.
      // We'll calculate the percentage of the container that we've scrolled through, where 0 is when the
      // top of the container is at the top of the screen, and 100 is when the bottom of the container is at
      // the bottom of the screen.
      const container = containerRef.current;
      const progressBar = progressBarRef.current;
      if (!container || !progressBar) return;
      const containerRect = container.getBoundingClientRect();
      const topOfContainer = containerRect.top || 0;
      const containerHeight = containerRect.height;
      const windowHeight = windowIfAvailable?.innerHeight ?? 0;
      const maxFrames = anim.totalFrames;
      const percentScrollProgress =
        (-1 * topOfContainer) / (containerHeight - windowHeight);
      const startOffset = 0.05;
      const endOffset = 0.95;
      const percentAnimationProgress =
        (percentScrollProgress - startOffset) /
        (1 - startOffset - (1 - endOffset));
      if (introRef.current) {
        introRef.current.style.opacity =
          percentScrollProgress > startOffset - 0.01 ? "0" : "1";
      }
      if (outroRef.current) {
        const isVisible = percentScrollProgress > endOffset;
        outroRef.current.style.opacity = isVisible ? "1" : "0";
        outroRef.current.style.pointerEvents = isVisible ? "auto" : "none";
      }
      progressBar.style.width = `${Math.max(
        0,
        Math.min(100, percentScrollProgress * 100),
      )}%`;
      if (percentAnimationProgress < 0) {
        if (anim.currentFrame !== -1) {
          progressBar.style.width = "0";
          anim.goToAndStop(-1, true);
        }
        return;
      }
      if (percentAnimationProgress > 1) {
        if (anim.currentFrame !== maxFrames) {
          setShowAnimation(false);
          anim.goToAndStop(maxFrames - 1, true);
        }
        return;
      }

      if (topGridRef.current && bottomGridRef.current) {
        const rotation = Math.min(
          Math.max(0, (percentScrollProgress - 0.05) * 300),
          30,
        );
        const transformTop = `perspective(400px) rotateX(-${rotation}deg)`;
        topGridRef.current.style.transform = transformTop;
        const transformBottom = `perspective(400px) rotateX(${rotation}deg)`;
        bottomGridRef.current.style.transform = transformBottom;
      }

      const frame = percentAnimationProgress * maxFrames;
      anim.goToAndStop(frame || -1, true);

      setShowAnimation(percentScrollProgress < 1);
      setShowProgressBar(
        percentScrollProgress < 1 && percentScrollProgress > 0,
      );
    };

    document.addEventListener("scroll", onScroll, true);
    onScroll();

    return () => {
      anim.destroy();
      document.removeEventListener("scroll", onScroll, true);
    };
  }, [isMobile, launched, renderCount]);

  const router = useRouter();
  const handleReadMethodology = useCallback(() => {
    router.push(FrecWebPath.whitepaper);
  }, [router]);

  const returnToSite = useCallback(() => {
    setLaunched(false);
  }, []);

  const handleLaunch = useCallback(() => {
    setLaunched(true);
  }, []);

  const description = (
    <>
      <FadeInZoom>
        <h2>Next-level index investing</h2>
      </FadeInZoom>
      <FadeInZoom>
        <div className="mb-4 max-w-[540px] leading-6">
          What if you could passively invest in the market while potentially
          saving thousands on your taxes?
        </div>
        <div className="max-w-[550px]">
          It’s called{" "}
          <BubbleText className="text-frecBlack mr-2 px-2! pt-1!">
            Frec Direct Indexing.
          </BubbleText>
          <wbr />
          And it takes zero additional effort.
        </div>
      </FadeInZoom>
    </>
  );

  return (
    <>
      <div
        className="relative flex cursor-pointer flex-col items-center"
        onClick={handleLaunch}
      >
        <div className="sticky top-0 flex h-[100vh] w-full flex-col justify-center">
          <div className="bgTileTop bgTileTopSkew absolute top-0 h-[500px] w-full" />
          <div className="h-[70vh]"></div>
          <div className="bgTileBottom bgTileBottomSkew absolute bottom-0 -z-10 h-[500px] w-full" />
        </div>
        <div className="text-frecBeige font-regular absolute top-[20vh] flex flex-col items-center gap-12 px-6 py-24 text-center">
          {description}
          <FadeInZoom className="flex flex-col items-center gap-6">
            <div
              role="button"
              onClick={handleLaunch}
              className="text-frecWhite flex flex-col items-center gap-2 font-medium"
            >
              <Image
                src={Tappie}
                alt="Tap to open the full screen modal"
                width={48}
                height={48}
              />
              See how it works
            </div>
          </FadeInZoom>
        </div>
      </div>
      <Modal
        classNames={{
          body: "p-0 min-h-full",
        }}
        opened={launched}
        onClose={() => setLaunched(false)}
        fullScreen
        withCloseButton={false}
        withinPortal
      >
        <section className="bg-frecBlack animation-cursor relative">
          <div
            ref={containerRef}
            className="relative flex h-[400rem] flex-col items-center"
          >
            <div className="sticky top-0 flex h-[100vh] w-full flex-col justify-center">
              <div
                className="bgTileTop absolute top-0 h-[500px] w-full"
                ref={topGridRef}
              />
              <div
                className={classNames(
                  "h-[70vh] transition duration-500",
                  showAnimation ? "" : "opacity-50 blur-xs",
                )}
                ref={lottieRef}
              ></div>
              <div
                className="bgTileBottom absolute bottom-0 -z-10 h-[500px] w-full"
                ref={bottomGridRef}
              />
              <div
                className={classNames(
                  "bg-frecWhite absolute top-0 h-1 w-0",
                  showProgressBar ? "" : "hidden",
                )}
                ref={progressBarRef}
              ></div>
              <CloseButton
                aria-label="Close"
                className="hover:bg-frecWhite/20 focus:bg-frecWhite/10 text-frecWhite/90 absolute top-8 right-8 cursor-pointer rounded-full focus:border-0!"
                size={isMobile ? 24 : 36}
                onClick={returnToSite}
              />
            </div>
            <div
              ref={introRef}
              className="text-frecBeige font-regular absolute top-[20vh] flex flex-col items-center gap-12 px-6 py-24 text-center transition-opacity duration-500"
            >
              {description}
              <FadeInZoom className="flex flex-col items-center gap-6">
                <Image
                  src={Scrollie}
                  alt="Please scroll down"
                  width={24}
                  height={24}
                />
                Scroll to learn more
              </FadeInZoom>
            </div>
          </div>
          <div
            ref={outroRef}
            className="absolute bottom-[10vh] z-10 mx-auto flex w-full transform-gpu flex-col items-center gap-12 px-6 text-center text-balance opacity-0 transition-opacity duration-500"
          >
            <Button
              className={classNames(
                "group flex items-center",
                smallOutlineClassNames,
              )}
              onClick={handleReadMethodology}
              small
            >
              Looking for the nitty gritty? Read about our
              methodology&nbsp;&nbsp;→
            </Button>
            <Button
              className={classNames(
                "flex items-center gap-2",
                smallOutlineClassNames,
              )}
              onClick={returnToSite}
              small
            >
              Close
            </Button>
          </div>
        </section>
      </Modal>
    </>
  );
};
