import { useObjectFeatureFlag } from "@frec-js/common-web";
import { HomeBanner } from "./HomeBanner";
import {
  FeatureFlags,
  GlobalBannerValue,
  MarketingBannerValue,
} from "@frec-js/common-client";

export const FeatureFlagBanner = ({ isMobile }: { isMobile?: boolean }) => {
  const marketingBanner = useObjectFeatureFlag<MarketingBannerValue>(
    FeatureFlags.MarketingBanner,
    {
      loading: {
        text: "",
      },
      missing: {
        text: "",
      },
    },
    // null user because frec-web is non-logged in
    {
      user: null,
      isEmployee: false,
    },
  );

  const globalBanner = useObjectFeatureFlag<GlobalBannerValue>(
    FeatureFlags.GlobalBanner,
    {
      loading: {
        text: "",
      },
      missing: {
        text: "",
      },
    },
    // null user because frec-web is non-logged in
    {
      user: null,
      isEmployee: false,
    },
  );

  if (!globalBanner.text && !marketingBanner.text) {
    return null;
  }

  // Global banner has higher priority
  if (globalBanner.text) {
    return (
      <HomeBanner className={globalBanner.className}>
        {globalBanner.text}
      </HomeBanner>
    );
  }

  return (
    <HomeBanner
      className={marketingBanner.className}
      href={marketingBanner.href}
      ctaText={
        isMobile
          ? (marketingBanner.ctaMobileText ?? marketingBanner.ctaText)
          : marketingBanner.ctaText
      }
    >
      {isMobile
        ? (marketingBanner.mobileText ?? marketingBanner.text)
        : marketingBanner.text}
    </HomeBanner>
  );
};
