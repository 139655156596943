import { makeDecimal, pluralWords } from "@frec-js/common";
import { UilAngleDown } from "@iconscout/react-unicons";
import { ComboboxItem, NumberInput, Select } from "@mantine/core";
import Decimal from "decimal.js";
import { FC, useCallback, useState } from "react";

import { classNames } from "@frec-js/common-web";
import { IndexAnchor } from "../../components/Anchor";
import {
  alphaPercentStr,
  TimeHorizon,
  useDirectIndexCalculatorData,
} from "../../components/DirectIndexCalculatorData";
import { DirectIndexCalculatorGraph } from "../../components/DirectIndexCalculatorGraph";
import { Expandable } from "../../components/Expandable";
import { Odometer } from "../../components/Odometer";
import { StartInvestingButton } from "../../components/StartInvestingButton";

export const timeHorizonOptions: ComboboxItem[] = [
  { label: "1 year", value: TimeHorizon.OneYear },
  // { label: "3 year", value: TimeHorizon.ThreeYears },
  { label: "5 years", value: TimeHorizon.FiveYears },
  // { label: "7 year", value: TimeHorizon.SevenYears },
  { label: "10 years", value: TimeHorizon.TenYears },
];

const FORMATTER = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const DirectIndexCalculator: FC = () => {
  const [portfolioValue, setPortfolioValue] = useState<Decimal>(
    new Decimal(100_000),
  );
  const [timeHorizon, setTimeHorizon] = useState<TimeHorizon>(
    TimeHorizon.TenYears,
  );
  const handleChangePortfolioValue = useCallback(
    (text: number | string) => {
      setPortfolioValue(makeDecimal(text));
    },
    [setPortfolioValue],
  );
  const handleChangeTimeHorizon = useCallback(
    (text: string | null) => {
      setTimeHorizon((text as TimeHorizon) ?? TimeHorizon.TenYears);
    },
    [setTimeHorizon],
  );

  const data = useDirectIndexCalculatorData(portfolioValue, timeHorizon);
  const lastData = data?.[data.length - 1];
  const improvement = lastData ? Math.floor(lastData?.sp500DIDiff) : undefined;
  const diPortfolioValue = lastData ? Math.floor(lastData?.sp500DI) : undefined;

  return improvement === undefined || diPortfolioValue === undefined ? null : (
    <>
      <section
        id={IndexAnchor.DirectIndexCalculator}
        className="grid grid-cols-[auto_minmax(0,86rem)_auto] grid-rows-[auto_1px_auto_1px_1fr_0_0] lg:grid-rows-[auto_1px_auto_1px_1fr_1px_96px]"
      >
        <div className="">{/* empty */}</div>
        <div className="dotted-v flex flex-col justify-end px-6 pt-16 pb-9 lg:mx-12 lg:border-x lg:px-16">
          <h2 className="h2 contents">
            How much more could your portfolio grow by reinvesting your tax
            savings?
          </h2>
        </div>
        <div className="">{/* empty */}</div>
        {/* row */}
        <div className="dotted-h col-span-3 border-b">{/* empty */}</div>
        {/* row */}
        <div className="">{/* empty */}</div>
        <div className="dotted-v lg:mx-12 lg:border-x">
          <div className="grid h-full grid-rows-[auto_auto] lg:grid-cols-[1fr_2fr] lg:grid-rows-none">
            <div className="dotted-v flex flex-col gap-12 px-6 py-8 lg:border-r lg:py-12 lg:pb-32 xl:px-16">
              <DIGraphInput
                label="Portfolio value"
                value={portfolioValue.toNumber()}
                onChange={handleChangePortfolioValue}
              />
              <DIGraphSelect
                label="Time horizon"
                data={timeHorizonOptions}
                value={timeHorizon}
                onChange={handleChangeTimeHorizon}
              />
              <div className="mt-6">
                <StartInvestingButton />
              </div>
            </div>
            <div className="relative min-h-[576px] w-full lg:min-h-[712px]">
              <div className="z-10 flex flex-col gap-2 px-6 py-12 lg:absolute lg:px-12">
                <div className="lg:text-20 font-normal">
                  In {pluralWords(parseInt(timeHorizon.toString()), "year")},
                  your portfolio could be worth
                </div>
                <div className="w-fit text-[40px] font-normal lg:text-[56px]">
                  $
                  <Odometer
                    value={diPortfolioValue}
                    formatter={FORMATTER.format}
                  />
                </div>
                <div className="lg:text-20 font-normal">
                  which is{" "}
                  <span className="bg-frecNeon">
                    $
                    <Odometer
                      value={improvement}
                      formatter={FORMATTER.format}
                    />
                  </span>{" "}
                  more than investing in an ETF
                </div>
                <div className="mt-6 max-w-lg rounded-2xl border p-5">
                  Daily tax loss harvesting can capture{" "}
                  <span className="text-frecBlue">
                    up to $19 in incremental tax savings for every $100
                    deposited
                  </span>{" "}
                  and tack on an incremental 2% per year.
                </div>
              </div>
              <div className="absolute top-0 right-0 bottom-0 left-0 -z-10 mx-0 mt-96 block h-48 lg:mt-40 lg:h-auto">
                <DirectIndexCalculatorGraph
                  principal={portfolioValue}
                  timeHorizon={timeHorizon}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="">{/* empty */}</div>
        {/* row */}
        <div className="dotted-h col-span-3 border-b">{/* empty */}</div>
        {/* row */}
        <div className="">{/* empty */}</div>
        <div
          className="dotted-v px-6 py-6 text-[12px] lg:mx-12 lg:border-x lg:px-16 lg:text-[14px]"
          data-nosnippet
        >
          <Expandable
            head="Read important disclosures"
            className="text-frecBlack"
          >
            <p className="text-frecBlack/50 text-12">
              <DirectIndexCalculatorSmallprint />
            </p>
          </Expandable>
        </div>
        <div className="">{/* empty */}</div>
        {/* row */}
        <div className="dotted-h col-span-3 lg:border-b">{/* empty */}</div>
        {/* row */}
        <div className=""></div>
        <div className="dotted-v lg:mx-12 lg:border-x">{/* empty */}</div>
        <div className="">{/* empty */}</div>
      </section>
    </>
  );
};

export const DIGraphInput: FC<{
  label: string;
  value: number;
  onChange: (value: number | string) => void;
}> = ({ label, value, onChange }) => {
  const handleChange = useCallback(
    (value: number | string) => {
      onChange(value === "" ? 0 : value);
    },
    [onChange],
  );
  return (
    <div className="flex flex-col gap-6">
      <label className="lg:text-20 text-frecBlack w-full text-[18px] font-normal md:w-1/3 lg:w-full">
        {label}
        <NumberInput
          classNames={{
            input:
              "border-0 blue-cursor w-full px-0 py-2 font-normal text-[32px] lg:text-[40px] border-b-4 bg-transparent! text-frecBlack border-frecBlack h-fit rounded-none!",
          }}
          hideControls
          defaultValue={value}
          onChange={handleChange}
          prefix="$"
          allowDecimal={false}
          thousandSeparator=","
        />
      </label>
    </div>
  );
};

export const DIGraphSelect: FC<{
  label: string;
  value: string;
  onChange: (value: string | null) => void;
  data: ComboboxItem[];
}> = ({ label, value, onChange, data }) => {
  const handleChange = useCallback(
    (value: string | null) => {
      onChange(value);
    },
    [onChange],
  );
  return (
    <div className="flex flex-col gap-6">
      <label className="lg:text-20 text-frecBlack w-full text-[18px] font-normal md:w-1/3 lg:w-full">
        {label}
        <Select
          allowDeselect={false}
          classNames={{
            input: classNames(
              "border-0 w-full rounded-none! px-0 py-2 font-normal text-[32px] lg:text-[40px] border-b-4 bg-transparent! text-frecBlack border-frecBlack h-fit",
            ),
            dropdown: "rounded-none bg-frecBeige border-black px-0 py-2",
            option: "rounded-none hover:bg-frecDarkBeige text-20 px-4",
          }}
          data={data}
          defaultValue={value}
          onChange={handleChange}
          rightSection={
            <UilAngleDown className="text-frecBlack h-8 w-8 cursor-pointer lg:h-20 lg:w-20" />
          }
          checkIconPosition="right"
        />
      </label>
    </div>
  );
};

export const DirectIndexCalculatorSmallprint: FC = () => {
  return (
    <>
      Frec&apos;s investment analysis tool above is for illustrative purposes
      only. The solid black line is hypothetical results from Frec&apos;s Direct
      Indexing Model (&quot;Model&quot;) tracking the S&P 500 index, and the
      dotted black line represents the {alphaPercentStr}% difference from SPY.
      Actual performance of the Model may differ based on your actual tax rate
      and tax circumstances, initial and recurring deposits or withdrawals, tax
      regulations, and time-frame. This tool uses the results from the Model
      simulations that tracked the S&P 500 index and were averaged at the end of
      year ten, which were 45.1% accumulated tax loss savings that were
      reinvested with a 42.3% tax rate, and included Frec&apos;s 0.10% fee,
      resulting in a {alphaPercentStr}% additional return when reinvesting tax
      losses. $19 of $100 in tax savings is calculated using 45.1% harvested and
      deducting from that 42.3% for taxes. The simulations were run to tax loss
      harvest on a weekly basis in a ten-year time frame of ninety day
      increments from December 17, 2003 through June 10, 2022 with a $50,000
      initial deposit. For the purposes of this tool, the tax loss harvesting
      return and annual tax alpha remain as a constant for the selection of
      years: 1, 5, and 10. The actual returns may vary the longer you remain
      invested. The prices used for stocks were adjusted for dividends and
      corporate actions. This calculator should not be considered tax advice and
      Frec does not provide tax advice. You should consult your legal, tax, or
      financial advisor before making any financial decisions. IMPORTANT: The
      projections or other information generated by Frec&apos;s Tax Calculator
      regarding the likelihood of various investment outcomes are hypothetical
      in nature, do not reflect actual investment results and are not guarantees
      of future results.
    </>
  );
};
