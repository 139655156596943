import {
  Icon,
  UilLockAlt,
  UilShield,
  UilUniversity,
} from "@iconscout/react-unicons";
import Image from "next/image";
import { FC, useCallback } from "react";

import { DirectIndexType } from "@frec-js/common-client";
import { classNames } from "@frec-js/common-web";
import { Avatar } from "@mantine/core";
import { exploreIndicesAnchor, IndexAnchor } from "../../components/Anchor";
import { BubbleText } from "../../components/BubbleText";
import { Button, TextButton } from "../../components/Button";
import { DirectIndexingIcon } from "../../components/DirectIndexingIcon";
import { SmoothScrollLink } from "../../components/Link";
import { useIsMobile } from "../../hooks/useIsMobile";
import ScreenshotDIPortfolioMobile from "../../public/webstatic/images/screenshot-direct-indexing-hero-mobile.png";
import ScreenshotDIPortfolio from "../../public/webstatic/images/screenshot-direct-indexing-hero.png";
import { BrokerageWebPath } from "../../utils/paths";

export const LandingHero: FC = () => {
  return (
    <>
      {/* !! height depends on screenshot */}
      <section className="grid grid-cols-[auto_minmax(0,86rem)_auto] grid-rows-[auto_1px_1fr] overflow-x-hidden">
        <div className="">{/* empty */}</div>
        <div className="dotted-v relative -mt-12 flex flex-col px-6 pb-3 lg:border-x lg:px-16 lg:pb-9">
          <h1 className="text-32 mt-28 max-w-[650px] lg:mt-48 lg:text-[48px] lg:leading-normal">
            Index investing that
            <br />
            gets you
            <BubbleText className="ml-2 lg:ml-3">more.</BubbleText>
          </h1>
          {/* left margin is width of text minus 50px */}
          <div className="z-10 mt-6 hidden lg:absolute lg:top-[180px] lg:left-[600px] lg:mt-0 lg:block">
            <DIScreenshot />
          </div>
        </div>
        <div className="row-span-3">{/* empty */}</div>
        <div className="dotted-h col-span-2 lg:border-b">{/* empty */}</div>
        <div className="">{/* empty */}</div>
        <article className="dotted-v relative flex flex-col pb-8 lg:border-x lg:pb-32">
          <p className="px-8 py-4 text-left lg:max-w-[600px] lg:p-16 lg:pb-6 lg:text-[20px]">
            Just as easy as investing in an ETF,{" "}
            <span className="whitespace-nowrap">Frec Direct Indexing</span> can
            help you earn more by unlocking tax savings, no matter the market.
            <br />
            <br />
            Done for you, automatically.
          </p>
          <Button
            className="mx-6 mt-2 lg:mx-16 lg:w-fit"
            onClick={() => (location.href = BrokerageWebPath.signup)}
          >
            Get started
          </Button>
          <div className="p-6 lg:hidden">
            <DIScreenshot />
          </div>
          <div className="mx-6 mt-16 flex gap-6 md:mt-10 lg:mx-16">
            <LittleValuePropBullet
              className="border-0 md:border-l md:pl-3"
              text="Member SIPC"
              Icon={UilLockAlt}
            />
            <LittleValuePropBullet
              className="border-l pl-3"
              text="SEC Registered"
              Icon={UilUniversity}
            />
            <LittleValuePropBullet
              className="border-l pl-3"
              text="Fiduciary"
              Icon={UilShield}
            />
          </div>
        </article>
      </section>
    </>
  );
};

const DIScreenshot: FC = () => {
  const isMobile = useIsMobile();
  const onClickExploreIndices = useCallback(() => {
    const element = document.getElementById(exploreIndicesAnchor);
    if (!element) return;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  return (
    <div className="relative">
      {isMobile && <ImageDisclaimer className="pl-2 sm:text-right lg:mr-3" />}
      <Image
        src={isMobile ? ScreenshotDIPortfolioMobile : ScreenshotDIPortfolio}
        className="m-auto max-h-[100%] lg:m-0 lg:max-w-none"
        width={750}
        height={655}
        alt="Screenshot of Frec’s direct indexing product"
        priority
      />
      <div className="absolute -bottom-16 flex w-full flex-col gap-4 sm:-bottom-11 lg:-bottom-12">
        <div className="flex w-full justify-center">
          <Button
            small
            variant="blueoutline"
            onClick={() => (location.href = BrokerageWebPath.demo)}
          >
            See our live demo {"->"}
          </Button>
        </div>
        <div className="flex w-full justify-center">
          <TextButton
            className="text-frecXDarkGray group text-14 relative inline-block px-0 py-2 font-normal hover:outline-none"
            onClick={onClickExploreIndices}
          >
            <div className="flex items-center gap-2">
              <Avatar.Group spacing="sm">
                <Avatar size="sm">
                  <DirectIndexingIcon type={DirectIndexType.Sp500} size="xs" />
                </Avatar>
                <Avatar size="sm">
                  <DirectIndexingIcon
                    type={DirectIndexType.SpInfoTech}
                    size="xs"
                  />
                </Avatar>
                <Avatar size="sm">
                  <DirectIndexingIcon
                    type={DirectIndexType.CrspLargeCap}
                    size="xs"
                  />
                </Avatar>
              </Avatar.Group>
              <span className="group relative inline-block">
                Explore indices ↓
                <span className="bg-frecXDarkGray absolute bottom-[-3px] left-0 h-[2px] w-full origin-left scale-x-0 transform transition-transform duration-300 ease-in-out group-hover:scale-x-100"></span>
              </span>
            </div>
          </TextButton>
        </div>
      </div>
      {!isMobile && <ImageDisclaimer className="pl-2 sm:text-right lg:mr-3" />}
    </div>
  );
};

const LittleValuePropBullet: FC<{
  Icon: Icon;
  text: string;
  className?: string;
}> = ({ Icon, text, className }) => {
  return (
    <SmoothScrollLink
      anchor={IndexAnchor.YourMoneyIsSecure}
      className={classNames("border-frecDarkBeige flex", className)}
    >
      <div className="flex flex-col gap-1">
        <Icon className="text-frecBlack/50 h-5 w-5 shrink-0" />
        <div className="text-frecBlack/50 text-[14px] opacity-80">{text}</div>
      </div>
    </SmoothScrollLink>
  );
};

const ImageDisclaimer: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      aria-hidden
      className={classNames(
        "text-frecBlack/50 text-[10px] lg:text-[12px]",
        className,
      )}
      data-nosnippet
    >
      For illustrative purposes only
    </div>
  );
};
