import { UilAngleLeftB, UilAngleRightB } from "@iconscout/react-unicons";
import { Carousel } from "@mantine/carousel";
import { partition } from "lodash";
import Link from "next/link";
import { FC, useMemo } from "react";

import { BlogCard } from "../../components/BlogCard";
import { AnimatedIcon } from "../../components/Button";
import { TestimonialCategory } from "../../content/testimonials";
import { useIsMobile } from "../../hooks/useIsMobile";
import { PostType } from "../../utils/blogApi";
import { FrecWebPath } from "../../utils/paths";

export const LearnMore: FC<{ posts: PostType[] }> = ({ posts }) => {
  const isMobile = useIsMobile();
  const sortedPosts = useMemo(() => {
    const [pinned, other] = partition(
      posts.filter((p) => !p.categories.includes(TestimonialCategory)),
      (post) => post.tags.includes("direct-indexing"),
    );
    return [...pinned, ...other];
  }, [posts]);
  return (
    <section className="grid grid-cols-[auto_minmax(0,86rem)_auto] grid-rows-[13rem_1px_auto_1px_96px_1px]">
      <div className="">{/* empty */}</div>
      <div className="dotted-v flex flex-col-reverse px-6 pb-9 lg:mx-12 lg:border-x lg:px-16">
        <h2 className="h2">Learn more</h2>
      </div>
      <div className="">{/* empty */}</div>
      {/* row */}
      <div className="dotted-h col-span-3 border-b">{/* empty */}</div>
      {/* row */}
      <div className="">{/* empty */}</div>
      <div className="dotted-v lg:mx-12 lg:border-x">
        <Carousel
          align="start"
          draggable
          slideSize={isMobile === false ? "519px" : "214px"}
          inViewThreshold={1}
          slidesToScroll={1}
          classNames={{
            control:
              "bg-frecBeige w-10 h-10 border-frecDarkBeige! hide-inactive",
          }}
          nextControlIcon={<UilAngleRightB size={24} className="ml-1" />}
          nextControlProps={{
            "aria-label": "Next",
          }}
          previousControlIcon={<UilAngleLeftB size={24} className="mr-1" />}
          previousControlProps={{
            "aria-label": "Previous",
          }}
          controlsOffset="xs"
        >
          {sortedPosts.map((post, index) => (
            <Carousel.Slide key={"slide-" + index}>
              <BlogCard
                post={post}
                className="w-[214px] border-r lg:w-[519px]"
              />
            </Carousel.Slide>
          ))}
        </Carousel>
      </div>
      <div className="">{/* empty */}</div>
      {/* row */}
      <div className="dotted-h col-span-3 border-b">{/* empty */}</div>
      {/* row */}
      <div className="">{/* empty */}</div>
      <Link href={FrecWebPath.resources}>
        <div className="dotted-v hover:bg-frecDarkBeige/50 group flex h-full items-center px-6 font-medium lg:mx-12 lg:border-x lg:px-16">
          See all resources <AnimatedIcon />
        </div>
      </Link>
      <div className="">{/* empty */}</div>
      {/* row */}
      <div className="dotted-h col-span-3 border-b">{/* empty */}</div>
    </section>
  );
};
